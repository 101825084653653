import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { isDown } from '@/utils';
import { useCategory, useLocation } from '@/composables';
import * as Sentry from '@sentry/vue';

const { fetch: fetchLocation } = useLocation();
const { fetch: fetchCategory } = useCategory();

export const routerHistory = createWebHistory();

const routes: RouteRecordRaw[] = [
    {
        path: '/jobs/:id',
        component: () => import('@/components/EmptyRouterView.vue'),
        children: [
            {
                path: '',
                name: 'jobs.show',
                component: () => import('@/views/Job/Show.vue'),
            },
            {
                path: 'call',
                name: 'jobs.call',
                component: () => import('@/views/Job/Show.vue'),
            },
            {
                path: 'complete',
                name: 'jobs.complete',
                components: {
                    default: () => import('@/views/Job/Show.vue'),
                    dialog: () => import('@/views/Job/CompleteDialog.vue'),
                },
            },
            {
                path: 'qr',
                name: 'jobs.qr',
                component: () => import('@/views/Job/Show.vue'),
            },
            {
                path: 'support',
                name: 'jobs.support',
                component: () => import('@/views/Job/Show.vue'),
            },
        ],
    },
    {
        path: '/jobs',
        name: 'jobs.index',
        component: () => import('@/views/Job/Index.vue'),
    },
    {
        path: '/members',
        name: 'members.index',
        component: () => import('@/views/Member/Index.vue'),
    },
    {
        path: '/members/create',
        name: 'members.create',
        component: () => import('@/views/Member/Index.vue'),
    },
    {
        path: '/members/:id/delete',
        name: 'members.delete',
        component: () => import('@/views/Member/Index.vue'),
    },
    {
        path: '/wallet',
        name: 'wallet.index',
        component: () => import('@/views/Wallet/Index.vue'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
    },
    {
        path: '/layout',
        component: () => import('@/components/Layout.vue'),
        children: [
            {
                path: '/onboard',
                name: 'onboard.index',
                component: () => import('@/views/Onboard/Index.vue'),
            },
            {
                path: '/onboard',
                component: () => import('@/views/Onboard/Layout.vue'),
                children: [
                    {
                        path: 'personal',
                        name: 'onboard.personal',
                        component: () => import('@/views/Onboard/Personal.vue'),
                    },
                    {
                        path: 'document',
                        name: 'onboard.document',
                        component: () => import('@/views/Onboard/Document.vue'),
                    },
                    {
                        path: 'service',
                        name: 'onboard.service',
                        component: () => import('@/views/Onboard/Service.vue'),
                    },
                    {
                        path: 'submit',
                        name: 'onboard.submit',
                        component: () => import('@/views/Onboard/Submit.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '/account',
        component: () => import('@/layouts/panel.vue'),
        children: [
            {
                path: '',
                name: 'account.index',
                component: () => import('@/views/Account/Index.vue'),
            },
        ],
    },
    {
        path: '/account/update',
        component: () => import('@/layouts/standalone.vue'),
        children: [
            {
                path: '',
                name: 'account.update',
                component: () => import('@/views/Account/Update.vue'),
            },
        ],
    },
    {
        path: '/session',
        name: 'session',
        component: () => import('@/views/Login/Session.vue'),
        meta: { guest: true },
    },
    {
        path: '/session/otp',
        name: 'session.otp',
        component: () => import('@/views/Login/Session/Otp.vue'),
        meta: { guest: true },
    },
    {
        path: '/sign',
        name: 'sign',
        component: () => import('@/views/Sign.vue'),
        meta: { guest: true },
    },
    {
        path: '/',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: { guest: true },
    },
    {
        path: '/down',
        name: 'down',
        component: () => import('@/views/Down.vue'),
        meta: { guest: true },
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/NotFound.vue'),
        meta: { guest: true },
    },
];

const router = createRouter({
    routes,
    history: routerHistory,
});

router.beforeEach(async (to) => {
    if (isDown() && to.path != '/down') return { path: '/down', replace: true };

    const auth = useAuthStore();

    if (auth.isLoggedIn) {
        Sentry.setUser({ id: auth.user!.id, name: auth.user!.name });
    } else {
        Sentry.setUser(null);
    }

    const { guest = false } = to.meta;
    if (guest && !auth.isLoggedIn) return;

    if (!auth.isLoggedIn) return { name: 'login', replace: true, query: { continue: to.path } };

    fetchCategory();
    fetchLocation();

    const isApproved = auth.user?.profile?.status == 'approved';

    if (to.name && ['login', 'down'].includes(to.name!.toString())) {
        return isApproved ? { path: '/dashboard', replace: true } : { path: '/onboard', replace: true };
    }
    if (!isApproved && to.name && !to.name!.toString().startsWith('onboard.')) {
        return { path: '/onboard', replace: true };
    }
});

router.afterEach((to, from) => {
    const toDepth = to.path.split('/').length;
    const fromDepth = from.path.split('/').length;
    if (toDepth == fromDepth) {
        to.meta.transition = 'fade';
        return;
    }
    // to.meta.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    to.meta.transition = 'fade'; // TODO: remove temporary overwrite
});

export { routes };

export default router;
